import {StartNode} from './start-node';
import {ManualTriggerNode} from './manual-trigger-node';
import {CreateDocumentNode} from './create-document-node';
import {AndJoinNode} from './and-join-node';
import {OrJoinNode} from './or-join-node';
import {ForEachNode} from './for-each-node';
import {SetProcessRunStateNode} from './set-process-run-state-node';
import {SetProcessRunNameNode} from './set-process-run-name-node';
import {SetProcessRunCustomDataNode} from './set-process-run-custom-data-node';
import {AppendProcessFlowNode} from './append-process-flow-node';
import {SendEmailNode} from './send-email-node';
import {HttpNode} from './http-node';
import {RequestFormNode} from './request-form-node';
import {SetProcessRunLabelsNode} from './set-process-run-labels-node';
import {ConditionalNode} from './conditional-node';

export const ProcessFlowNodeType = {
  StartNode: 'ProcessFlow::StartNode',
  ManualTriggerNode: 'ProcessFlow::ManualTriggerNode',
  CreateDocumentNode: 'ProcessFlow::CreateDocumentNode',
  AndJoinNode: 'ProcessFlow::AndJoinNode',
  OrJoinNode: 'ProcessFlow::OrJoinNode',
  ForEachNode: 'ProcessFlow::ForEachNode',
  SetProcessRunStateNode: 'ProcessFlow::SetProcessRunStateNode',
  SetProcessRunNameNode: 'ProcessFlow::SetProcessRunNameNode',
  SetProcessRunCustomDataNode: 'ProcessFlow::SetProcessRunCustomDataNode',
  AppendProcessFlowNode: 'ProcessFlow::AppendProcessFlowNode',
  SendEmailNode: 'ProcessFlow::SendEmailNode',
  HttpNode: 'ProcessFlow::HttpNode',
  RequestFormNode: 'ProcessFlow::RequestFormNode',
  SetProcessRunLabelsNode: 'ProcessFlow::SetProcessRunLabelsNode',
  ConditionalNode: 'ProcessFlow::ConditionalNode'
} as const;

export type ProcessFlowNodeType = typeof ProcessFlowNodeType[keyof typeof ProcessFlowNodeType];

export type ProcessFlowNode =
    StartNode
  | ManualTriggerNode
  | CreateDocumentNode
  | AndJoinNode
  | OrJoinNode
  | ForEachNode
  | SetProcessRunStateNode
  | SetProcessRunNameNode
  | SetProcessRunCustomDataNode
  | AppendProcessFlowNode
  | SendEmailNode
  | HttpNode
  | RequestFormNode
  | SetProcessRunLabelsNode
  | ConditionalNode;

export function isStartNode(node: ProcessFlowNode): node is StartNode {
  return node instanceof StartNode;
}

export function isManualTriggerNode(node: ProcessFlowNode): node is ManualTriggerNode {
  return node instanceof ManualTriggerNode;
}

export function isCreateDocumentNode(node: ProcessFlowNode): node is CreateDocumentNode {
  return node instanceof CreateDocumentNode;
}

export function isAndJoinNode(node: ProcessFlowNode): node is AndJoinNode {
  return node instanceof AndJoinNode;
}

export function isOrJoinNode(node: ProcessFlowNode): node is OrJoinNode {
  return node instanceof OrJoinNode;
}

export function isForEachNode(node: ProcessFlowNode): node is ForEachNode {
  return node instanceof ForEachNode;
}

export function isSetProcessRunStateNode(node: ProcessFlowNode): node is SetProcessRunStateNode {
  return node instanceof SetProcessRunStateNode;
}

export function isSetProcessRunNameNode(node: ProcessFlowNode): node is SetProcessRunNameNode {
  return node instanceof SetProcessRunNameNode;
}

export function isSetProcessRunCustomDataNode(node: ProcessFlowNode): node is SetProcessRunCustomDataNode {
  return node instanceof SetProcessRunCustomDataNode;
}

export function isAppendProcessFlowNode(node: ProcessFlowNode): node is AppendProcessFlowNode {
  return node instanceof AppendProcessFlowNode;
}

export function isSendEmailNode(node: ProcessFlowNode): node is SendEmailNode {
  return node instanceof SendEmailNode;
}

export function isHttpNode(node: ProcessFlowNode): node is HttpNode {
  return node instanceof HttpNode;
}

export function isRequestFormNode(node: ProcessFlowNode): node is RequestFormNode {
  return node instanceof RequestFormNode;
}

export function isSetProcessRunLabelsNode(node: ProcessFlowNode): node is SetProcessRunLabelsNode {
  return node instanceof SetProcessRunLabelsNode;
}

export function isConditionalNode(node: ProcessFlowNode): node is ConditionalNode {
  return node instanceof ConditionalNode;
}
