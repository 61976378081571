import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '@environment';
import {Observable, throwError} from 'rxjs';
import {
  CreateQesIdentityVerificationProcess,
  CreateQesRegistrationProcess,
  CreateQesUser,
  Participant,
  ParticipationSubmission,
  QesIdentityVerificationProcess,
  QesRegistrationProcess,
  QesSignatureProcess,
  QesUser,
  SubmissionMetaInformation,
  SubmitEventValue
} from '@paperlessio/sdk/api/models';
import {SubmitEventValueSerializer} from '@paperlessio/sdk/api/serializers';
import {catchError, map} from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import {ParticipationSubmissionSerializer} from '@shared/submission/participation-submission.serializer';
import {ParticipationSubmitEvent} from '@shared/submission/participation-submit-event';
import {ParticipationSubmitEventSerializer} from '@shared/submission/participation-submit-event.serializer';
import {ParticipationCompletion} from '@shared/submission/participation-completion';
import {ForwardDelegateParams} from './forward-delegate-params';

@Injectable({providedIn: 'root'})
export class ParticipationService {
  BASE_URL = environment.api.core + 'v1/';
  endpoint = 'participation';
  submitEventValueSerializer = new SubmitEventValueSerializer();

  constructor(private http: HttpClient) {}

  getSubmissionMetaInformation(participant_token: string): Observable<SubmissionMetaInformation> {
    return this.http.get<SubmissionMetaInformation>(this.BASE_URL + this.endpoint + '/submission_meta_information/' + participant_token)
      .pipe(
        map(smi => {
          return new SubmissionMetaInformation(smi);
        }),
        catchError((e: HttpErrorResponse) => {
          if (e.status !== 404) {
            Sentry.captureException(e);
          }

          return throwError(e);
        })
      );
  }

  startSession(participant_token: string): Observable<{participant: Participant}> {
    return this.http.post<{participant: Participant}>(this.BASE_URL + this.endpoint + '/authentication/session', {participant_token})
      .pipe(
        catchError(e => {
          if (e.status !== 404) {
            Sentry.captureException(e);
          }

          return throwError(e);
        })
      );
  }

  getSubmission(id: number): Observable<ParticipationSubmission> {
    return this.http.get<ParticipationSubmission>(this.BASE_URL + this.endpoint + '/submissions/' + id)
      .pipe(
        map(json => {
          return new ParticipationSubmissionSerializer().fromJson(json);
        }),
        catchError(e => {
          if (e.status !== 404) {
            Sentry.captureException(e);
          }

          return throwError(e);
        })
      );
  }

  createTermsAndConditionsApproval(participant_token: string) {
    return this.http.post<ParticipationSubmitEvent>(this.BASE_URL + this.endpoint + '/terms_and_conditions_approvals',
      {participant_token,}).pipe(
      catchError(e => {
        Sentry.captureException(e);
        return throwError(e);
      })
    );
  }

  createSubmitEvent(submission_id: number,
                    values: SubmitEventValue[],
                    page_block_id?: number): Observable<ParticipationSubmitEvent> {
    return this.http.post<ParticipationSubmitEvent>(this.BASE_URL + this.endpoint + '/submit_events',
      {
        submission_id,
        values: values.map(v => this.submitEventValueSerializer.serialize(v)),
        page_block_id
      }).pipe(
      map(json => {
        return new ParticipationSubmitEventSerializer().fromJson(json);
      }),
      catchError(e => {
        Sentry.captureException(e);
        return throwError(e);
      })
    );
  }

  createCompletion(submission_id: number) {
    return this.http
      .post<ParticipationCompletion>(this.BASE_URL + this.endpoint + '/completions', {submission_id})
      .pipe(
        map(result => new ParticipationCompletion(result)),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        })
      );
  }

  createPageOpenedActivityEvent(submission_id: number, page_block_id) {
    return this.http.post<ParticipationSubmitEvent>(this.BASE_URL + this.endpoint + '/activity_events',
      {
        submission_id,
        type: 'PageOpenedActivityEvent',
        page_block_id
      }).pipe(
      catchError(e => {
        Sentry.captureException(e);
        return throwError(e);
      })
    );
  }

  createPageEditingStartedActivityEvent(submission_id: number, page_block_id) {
    return this.http.post<ParticipationSubmitEvent>(this.BASE_URL + this.endpoint + '/activity_events',
      {
        submission_id,
        type: 'PageEditingStartedActivityEvent',
        page_block_id
      }).pipe(
      catchError(e => {
        Sentry.captureException(e);
        return throwError(e);
      })
    );
  }

  createAttachmentDownloadedActivityEvent(submission_id: number, attachment_block_id) {
    return this.http.post<ParticipationSubmitEvent>(this.BASE_URL + this.endpoint + '/activity_events',
      {
        submission_id,
        type: 'AttachmentDownloadedActivityEvent',
        attachment_block_id
      }).pipe(
      catchError(e => {
        Sentry.captureException(e);
        return throwError(e);
      })
    );
  }

  decline(submission_id: number, message?: string) {
    return this.http.post<SubmissionMetaInformation>(
      this.BASE_URL + this.endpoint + '/declinations',
      {submission_id, message})
      .pipe(
        map(smi => {
          return new SubmissionMetaInformation(smi);
        }),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        })
      );
  }

  forward(forwardParams: ForwardDelegateParams) {
    return this.http.post<SubmissionMetaInformation>(this.BASE_URL + this.endpoint + '/forwardings', forwardParams)
      .pipe(
        map(smi => {
          return new SubmissionMetaInformation(smi);
        }),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        })
      );
  }

  delegate(delegateParams: ForwardDelegateParams) {
    return this.http.post<SubmissionMetaInformation>(this.BASE_URL + this.endpoint + '/delegations', delegateParams)
      .pipe(
        map(smi => {
          return new SubmissionMetaInformation(smi);
        }),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        })
      );
  }

  createParticipationQesIdentityVerificationProcess(params: CreateQesIdentityVerificationProcess): Observable<QesIdentityVerificationProcess> {
    return this.http
      .post<QesIdentityVerificationProcess>(this.BASE_URL + this.endpoint + '/qes_identity_verification_processes', params)
      .pipe(
        map(ivp => new QesIdentityVerificationProcess(ivp)),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        })
      );
  }

  getParticipationQesSignatureProcessCompletion(signed_id: string) {
    return this.http.get(this.BASE_URL + this.endpoint + '/qes_signature_process_completions/' + signed_id);
  }

  getParticipationQesSignatureProcess(id: number): Observable<QesSignatureProcess> {
    return this.http
      .get<QesSignatureProcess>(this.BASE_URL + this.endpoint + '/qes_signature_processes/' + id)
      .pipe(
        map(sp => new QesSignatureProcess(sp)),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        })
      );
  }

  createParticipationQesSignatureProcess(username: string): Observable<QesSignatureProcess> {
    return this.http
      .post<QesSignatureProcess>(this.BASE_URL + this.endpoint + '/qes_signature_processes', {username})
      .pipe(
        map(sp => new QesSignatureProcess(sp)),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        })
      );
  }

  getParticipantQesUser(username: string): Observable<QesUser> {
    return this.http.get<QesUser>(this.BASE_URL + this.endpoint + '/qes_users/' + encodeURIComponent(username));
  }

  createParticipantQesUser(params: CreateQesUser): Observable<QesUser> {
    return this.http.post<QesUser>(this.BASE_URL + this.endpoint + '/qes_users', params);
  }

  createQesRegistrationProcess(params: CreateQesRegistrationProcess): Observable<QesRegistrationProcess> {
    return this.http.post<QesUser>(this.BASE_URL + this.endpoint + '/qes_registration_processes', params);
  }
}
