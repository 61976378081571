// eslint-disable-next-line max-classes-per-file
import {ProcessFlowBaseNode, ProcessFlowBaseNodeSettings} from './process-flow-base-node';
import {RexCalculationType} from '../../calculation-type';
import {Participant} from '../../participant';
import {ProcessFlowNodeOutlet} from './process-flow-node-outlets';
import {excluded} from '../../util/excluded';

export class CreateDocumentNode extends ProcessFlowBaseNode {
  settings: CreateDocumentNodeSettings;
  recalculated_settings: CreateDocumentNodeSettings;

  constructor(v: Partial<CreateDocumentNode>) {
    super(v);
    this.type = 'ProcessFlow::CreateDocumentNode';
    this.settings = new CreateDocumentNodeSettings(v.settings as CreateDocumentNodeSettings);
    if (v.recalculated_settings) {
      this.recalculated_settings = new CreateDocumentNodeSettings(v.recalculated_settings as CreateDocumentNodeSettings);
    }

    this.supportsOutlets = true;
    this.supportedOutlets = [
      ProcessFlowNodeOutlet.ANY_END_STATE_OUTLET,
      ProcessFlowNodeOutlet.SUBMISSION_DISPATCHED_OUTLET,
      ProcessFlowNodeOutlet.SUBMISSION_COMPLETED_OUTLET,
      ProcessFlowNodeOutlet.SUBMISSION_DECLINED_OUTLET,
      ProcessFlowNodeOutlet.SUBMISSION_CANCELLED_OUTLET,
      ProcessFlowNodeOutlet.SUBMISSION_EXPIRED_OUTLET,
    ];
  }
}

export class CreateDocumentNodeSettings extends ProcessFlowBaseNodeSettings {
  template_id_calculation_type: RexCalculationType;
  template_id_calculation_javascript_definition: string;
  template_id: number;

  workspace_id_calculation_type: RexCalculationType;
  workspace_id_calculation_javascript_definition: string;
  workspace_id: number;

  current_user_id: number;
  direct_dispatch: boolean;

  name_calculation_type: RexCalculationType;
  name_calculation_javascript_definition: string;
  name: string;

  participants_calculation_type: RexCalculationType;
  participants_calculation_javascript_definition: string;
  participants: { [slot_name: string]: Pick<Participant, 'name' | 'email' | 'slot_name' | 'dispatch_strategy' | 'color'> };

  tokens_calculation_type: RexCalculationType;
  tokens_calculation_javascript_definition: string;
  tokens: Record<string, string>;

  blocks_calculation_type: RexCalculationType;
  blocks_calculation_javascript_definition: string;
  blocks: Record<string, {
    default_value: any,
    default_value_from_value: any,
    visible: boolean
  }>;

  inherited_submit_event_values_calculation_type: RexCalculationType;
  inherited_submit_event_values_calculation_javascript_definition: string;
  inherited_submit_event_values: {
    submission_id: number,
    slugs: {
      all: boolean,
      only: string[],
      except: string[]
    }
  }[];

  @excluded visibleOutlets: string[];

  constructor(v: Partial<CreateDocumentNodeSettings> = {}) {
    super(v);

    this.template_id_calculation_type = v.template_id_calculation_type ?? RexCalculationType.CONSTANT;
    this.template_id_calculation_javascript_definition = v.template_id_calculation_javascript_definition ?? '';
    this.template_id = v.template_id ?? null;

    this.workspace_id_calculation_type = v.workspace_id_calculation_type ?? RexCalculationType.CONSTANT;
    this.workspace_id_calculation_javascript_definition = v.workspace_id_calculation_javascript_definition ?? '';
    this.workspace_id = v.workspace_id ?? null;

    this.current_user_id = v.current_user_id ?? null;
    this.direct_dispatch = v.direct_dispatch ?? false;

    this.name_calculation_type = v.name_calculation_type ?? RexCalculationType.CONSTANT;
    this.name_calculation_javascript_definition = v.name_calculation_javascript_definition ?? '';
    this.name = v.name ?? '';

    this.participants_calculation_type = v.participants_calculation_type ?? RexCalculationType.JAVASCRIPT;
    this.participants_calculation_javascript_definition = v.participants_calculation_javascript_definition ?? '';
    this.participants = v.participants ?? {};

    this.tokens_calculation_type = v.tokens_calculation_type ?? RexCalculationType.JAVASCRIPT;
    this.tokens_calculation_javascript_definition = v.tokens_calculation_javascript_definition ?? '';
    this.tokens = v.tokens ?? {};

    this.blocks_calculation_type = v.blocks_calculation_type ?? RexCalculationType.JAVASCRIPT;
    this.blocks_calculation_javascript_definition = v.blocks_calculation_javascript_definition ?? '';
    this.blocks = v.blocks ?? {};

    this.inherited_submit_event_values_calculation_type = v.inherited_submit_event_values_calculation_type ?? RexCalculationType.JAVASCRIPT;
    this.inherited_submit_event_values_calculation_javascript_definition = v.inherited_submit_event_values_calculation_javascript_definition ?? '';
    this.inherited_submit_event_values = v.inherited_submit_event_values ?? [];

    this.visibleOutlets = [];
  }
}
