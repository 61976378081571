// eslint-disable-next-line max-classes-per-file
import {ProcessFlowBaseNode} from './process-flow-base-node';
import {ProcessFlowNodeOutlet} from './process-flow-node-outlets';

export class ManualTriggerNode extends ProcessFlowBaseNode {
  constructor(v: Partial<ManualTriggerNode>) {
    super(v);
    this.type = 'ProcessFlow::ManualTriggerNode';
    this.iconClickable = true;

    this.supportedOutlets = [
      ProcessFlowNodeOutlet.COMPLETED_OUTLET
    ];
  }
}
