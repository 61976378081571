import {InjectionToken} from '@angular/core';

export const ENVIRONMENT_NAME = new InjectionToken<string>('ENVIRONMENT_NAME');

export function provideEnvironmentName(value: string) {
  return {
    provide: ENVIRONMENT_NAME,
    useValue: value
  };
}
