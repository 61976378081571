import {BehaviorSubject, Observable, of, ReplaySubject} from 'rxjs';
import {concatMap, filter, first, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {
  Blob,
  BucketType,
  CreateQesIdentityVerificationProcess,
  CreateQesRegistrationProcess,
  CreateQesUser,
  Document,
  FormVersion,
  Participant,
  ParticipationSubmission,
  QesIdentityVerificationProcess,
  QesRegistrationProcess,
  QesSignatureProcess,
  QesUser,
  RexCalculationType,
  Submission,
  SubmissionMetaInformation,
  SubmitEventValue
} from '@paperlessio/sdk/api/models';
import {SubmissionData} from 'projects/render/src/app/submission-render/submission-data';
import {ForwardDelegateParams} from './forward-delegate-params';
import {ParticipationService} from './participation.service';
import {CalculatedTokenValueStore} from '@shared/rex/calculated-token-value.store';
import {CalculatedBlockAttributeStore} from '@shared/rex/calculated-block-attribute.store';
import {CalculatedParticipantNodeAttributeStore} from '@shared/rex/calculated-participant-node-attribute.store';
import {TranslocoService} from '@ngneat/transloco';
import {LOCALE} from '@paperlessio/sdk/api/util';

// TODO: DON'T PROVIDE THIS IN ROOT! This has no business at all in management!
@Injectable({providedIn: 'root'})
export class SessionStore {
  participant_token: string;

  submission_id: number;

  submissionMetaInformation: BehaviorSubject<SubmissionMetaInformation> = new BehaviorSubject<SubmissionMetaInformation>(null);

  submission: BehaviorSubject<Submission | ParticipationSubmission | SubmissionData> =
    new BehaviorSubject<Submission | ParticipationSubmission | SubmissionData>(null);

  participant: ReplaySubject<Participant> = new ReplaySubject<Participant>();
  blockValues: BehaviorSubject<{[slug: string]: SubmitEventValue}> = new BehaviorSubject<{[slug: string]: SubmitEventValue}>(null);
  tosApproved = new BehaviorSubject<boolean>(false);

  currentQesUser = new BehaviorSubject<QesUser>(null);

  // VERY IMPORTANT
  // We MUST NEVER use POS/PUT/PATCH HTTP requests before we have a session from the server!
  authenticationSessionReady = new BehaviorSubject<boolean>(false);

  private participationService = inject(ParticipationService);
  private calculatedTokenValueStore = inject(CalculatedTokenValueStore);
  private calculatedBlockAttributeStore = inject(CalculatedBlockAttributeStore);
  private calculatedParticipantNodeAttributeStore = inject(CalculatedParticipantNodeAttributeStore);
  private translocoService = inject(TranslocoService);

  initMetaInformation(participant_token: string): Observable<SubmissionMetaInformation> {
    this.participant_token = participant_token;
    return this.participationService
      .getSubmissionMetaInformation(participant_token)
      .pipe(
        tap(submissionMetaInformation => {
          this.submissionMetaInformation.next(submissionMetaInformation);
          this.submission_id = submissionMetaInformation.submission_id;
          const tosApproved = !!submissionMetaInformation.participant.terms_and_conditions_approved_at;
          this.tosApproved.next(tosApproved);

          // handle special case: ParticipationSubmission includes aggregated_submit_event_values and submittable
          // this is done by special request of Jan to improve loading performance
          // it can only be done for submittables/submissions without any sort of protection (pw/2fa/etc)
          if (!this.submission.value) {
            this.submission.next(new ParticipationSubmission({
              id: submissionMetaInformation.submission_id,
              aggregated_submit_event_values: submissionMetaInformation.aggregated_submit_event_values,
              submittable: submissionMetaInformation.submittable.type === BucketType.FormVersion ? new FormVersion(submissionMetaInformation.submittable as any) : new Document(submissionMetaInformation.submittable)
            }));
            this.participant.next(submissionMetaInformation.participant);
            this.setBlockValuesFromAggregatedSubmitEventValues(submissionMetaInformation.aggregated_submit_event_values);
            this.calculatedTokenValueStore.loadConstantTokenValues(submissionMetaInformation.submittable?.tokens);
            this.calculatedTokenValueStore.loadAggregatedCalculatedTokenValues(submissionMetaInformation.aggregated_calculated_token_values);
            this.calculatedBlockAttributeStore.loadConstantBlockAttributes(submissionMetaInformation.submittable?.blocks);
            this.calculatedBlockAttributeStore.loadAggregatedCalculatedBlockAttributes(submissionMetaInformation.aggregated_calculated_block_attributes);

            if (submissionMetaInformation.submittable?.settings?.localeOverrides) {
              Object.keys(submissionMetaInformation.submittable?.settings?.localeOverrides).forEach(locale => {
                if (Object.values(LOCALE).includes(locale as LOCALE)) {
                  this.translocoService.setTranslation(
                    submissionMetaInformation.submittable?.settings?.localeOverrides[locale],
                    locale,
                    {merge: true}
                  );
                }
              });
            }
          }

          // part two of handling the above-mentioned special case
          if (tosApproved) {
            this.initSubmission().subscribe();
          }
        }),
      );
  }

  initSubmission(): Observable<ParticipationSubmission> {
    return this.participationService
      .startSession(this.participant_token)
      .pipe(
        tap(() => this.authenticationSessionReady.next(true)),
        tap(data => {
          this.participant.next(data.participant);
          if (data.participant && this.submissionMetaInformation.value) {
            const pmi = this.submissionMetaInformation.value;

            if (data.participant.stored_signature_image) {
              pmi.participant.stored_signature_image = new Blob(data.participant.stored_signature_image);
              pmi.participant.stored_signature_image.signed_id = data.participant.stored_signature_image.signed_id;
            }

            pmi.participant.qes_in_progress = data.participant.qes_in_progress;

            this.submissionMetaInformation.next(pmi);
          }
        }),
        mergeMap(() => this.fetchSubmission())
      );
  }

  fetchSubmission() {
    return this.participationService
      .getSubmission(this.submission_id)
      .pipe(
        tap((submission: ParticipationSubmission) => {
          this.submission.next(submission);
          this.setBlockValuesFromAggregatedSubmitEventValues(submission.aggregated_submit_event_values);
          this.calculatedTokenValueStore.loadConstantTokenValues(submission.submittable?.tokens);
          this.calculatedTokenValueStore.loadAggregatedCalculatedTokenValues(submission.aggregated_calculated_token_values);
          this.calculatedBlockAttributeStore.loadConstantBlockAttributes(submission.submittable?.blocks);
          this.calculatedBlockAttributeStore.loadAggregatedCalculatedBlockAttributes(submission.aggregated_calculated_block_attributes);
        })
      );
  }

  /**
   * Method to approve the tos.
   * @param fireTosApprovedSubject When true, tosAccepted subject is not fired. This is needed in the declined modal because we have an Observable that's just waiting to fire an activity_event in the session-page component and we don't want that.
   */
  approveTermsAndConditions(fireTosApprovedSubject = true): Observable<{}> {
    return this.participationService
      .createTermsAndConditionsApproval(this.participant_token)
      .pipe(
        switchMap(v => {
          return this.initSubmission()
            .pipe(
              tap(() => {
                // defer until we have a working session for everything that waits on tosApproved=true
                fireTosApprovedSubject && this.tosApproved.next(true);
              }),
              switchMap(x => of(v))
            );
        })
      );
  }

  submitValues(data: { [slug: string]: any }, page_block_id?: number) {
    const values: SubmitEventValue[] = [];

    Object.entries(data).forEach(([key, value]) => {
      values.push(new SubmitEventValue({slug: key, value}));
    });

    return this.participationService
      .createSubmitEvent(this.submission_id, values, page_block_id)
      .pipe(
        tap(persisted => {
          this.setBlockValuesFromAggregatedSubmitEventValues(persisted.submit_event_values);
        })
      );
  }

  get mayComplete(): Observable<boolean> {
    return this.participant.pipe(
      filter(participant => !!participant?.may_complete_calculation_type),
      switchMap(participant => {
        switch (participant?.may_complete_calculation_type) {
          case RexCalculationType.CONSTANT:
            return of(true);
          case RexCalculationType.JAVASCRIPT:
            return this.calculatedParticipantNodeAttributeStore.participantNodeAttributeValue(participant.id, 'may_complete').pipe(map(value => value === true));
        }
      }));
  }

  complete() {
    return this.participationService.createCompletion(this.submission_id);
  }

  createPageOpenedActivityEvent(page_block_id: number): Observable<{}> {
    // prevent duplicate event
    const progressEntry = this.submissionMetaInformation.value?.participant?.progress?.find(p => p.block_id = page_block_id);
    if (progressEntry?.state === 'opened') {
      return of(true);
    }

    // requires an authentication session from the server
    return this.authenticationSessionReady.pipe(
      filter(ready => ready === true),
      first(),
      switchMap(ready => this.tosApproved),
      filter(approved => approved === true),
      first(),
      concatMap(() => this.participationService.createPageOpenedActivityEvent(this.submission_id, page_block_id))
    );
  }

  createPageEditingStartedActivityEvent(page_block_id: number): Observable<{}> {
    return this.participationService.createPageEditingStartedActivityEvent(this.submission_id, page_block_id);
  }

  createAttachmentDownloadedActivityEvent(attachment_block_id: number): Observable<{}> {
    return this.participationService.createAttachmentDownloadedActivityEvent(this.submission_id, attachment_block_id);
  }

  setBlockValues(data: { [slug: string]: any }) {
    const existingValues = this.blockValues.value || {};
    this.blockValues.next({...existingValues, ...data});
  }

  setBlockValuesFromSubmitEventValues(aggregatedSubmitEventValues: SubmitEventValue[]) {
    const blockValues = {};
    aggregatedSubmitEventValues.forEach(value => {
      blockValues[value.slug] = value.value;
    });
    this.blockValues.next(blockValues);
  }

  getBlockData(slug: string): any {
    return this.blockValues.value?.[slug];
  }

  getAggregatedSubmissionData(slug: string): SubmitEventValue {
    return this.submission.value?.submitEventValueForSlug(slug);
  }

  decline(submission_id?: number, message?: string) {
    if (!submission_id) {
      submission_id = this.submission_id;
    }

    return this.participationService
      .decline(submission_id, message);
  }

  forward(forwardParams: ForwardDelegateParams) {
    return this.participationService
      .forward(forwardParams)
      .pipe(switchMap(() => this.fetchSubmission()));
  }

  delegate(delegateParams: ForwardDelegateParams) {
    return this.participationService
      .delegate(delegateParams)
      .pipe(
        switchMap(() => this.initMetaInformation(this.participant_token)),
        switchMap(() => this.fetchSubmission())
      );
  }

  createParticipationQesIdentityVerificationProcess(params: CreateQesIdentityVerificationProcess): Observable<QesIdentityVerificationProcess> {
    return this.participationService
      .createParticipationQesIdentityVerificationProcess(params)
      .pipe(
        // tap(result => {
        //
        // })
      );
  }

  getParticipationQesSignatureProcessCompletion(signed_id: string) {
    return this.participationService
      .getParticipationQesSignatureProcessCompletion(signed_id)
      .pipe(
        // tap(result => {
        //
        // })
      );
  }

  getParticipationQesSignatureProcess(id: number): Observable<QesSignatureProcess> {
    return this.participationService
      .getParticipationQesSignatureProcess(id)
      .pipe(
        // tap(result => {
        //
        // })
      );
  }

  createParticipationQesSignatureProcess(username: string): Observable<QesSignatureProcess> {
    return this.participationService
      .createParticipationQesSignatureProcess(username)
      .pipe(
        // tap(result => {
        //
        // })
      );
  }

  getParticipantQesUser(username: string): Observable<QesUser> {
    return this.participationService
      .getParticipantQesUser(username)
      .pipe(
        tap(result => {
          this.currentQesUser.next(result);
        })
      );
  }

  createParticipantQesUser(params: CreateQesUser): Observable<QesUser> {
    return this.participationService
      .createParticipantQesUser(params)
      .pipe(
        switchMap(result => this.getParticipantQesUser(result.username))
      );
  }

  createQesRegistrationProcess(params: CreateQesRegistrationProcess): Observable<QesRegistrationProcess> {
    return this.participationService.createQesRegistrationProcess(params);
  }

  private setBlockValuesFromAggregatedSubmitEventValues(aggregatedSubmitEventValues: SubmitEventValue[]) {
    const values = {};
    aggregatedSubmitEventValues.forEach((submitEventValue: SubmitEventValue) => {
      values[submitEventValue.slug] = submitEventValue.value;
    });
    const existingValues = this.blockValues.value || {};
    this.blockValues.next({...existingValues, ...values});
  }
}
