import {RexCalculationType} from '../calculation-type';

export class ListColumn {
  id: number;
  list_id: number;
  position: number;
  column_name: string;
  display_name: string;
  value_calculation_type: RexCalculationType;
  value_calculation_javascript_definition?: string;
  value_calculation_json_logic_definition?: any;
  value?: any;
  type: ListColumnType;
  settings: any;

  constructor(v?: Partial<ListColumn>) {
    this.id = v?.id;
    this.list_id = v?.list_id ?? 0;
    this.column_name = v?.column_name ?? '';
    this.display_name = v?.display_name ?? '';
    this.value_calculation_type = v?.value_calculation_type ?? RexCalculationType.JAVASCRIPT;
    this.value_calculation_javascript_definition = v.value_calculation_javascript_definition ?? '';
    this.value_calculation_json_logic_definition = v.value_calculation_json_logic_definition;
    this.value = v.value;
    this.type = v?.type ?? ListColumnType.StringColumn;
    this.settings = v?.settings;
  }

  get editable(): boolean {
    // @ts-ignore
    return this.type !== ListColumnType.IdColumn
      && this.type !== ListColumnType.WorkspaceIdColumn
      && this.type !== ListColumnType.IncinerationOrderIdColumn
      && this.type !== ListColumnType.UpdatedAtColumn
      && this.type !== ListColumnType.CreatedAtColumn;
  }

  setDefaults() {
    switch (this.type) {
      case ListColumnType.StringColumn:
        this.value_calculation_type = RexCalculationType.JAVASCRIPT;
        this.value_calculation_javascript_definition = '"Lorem Ipsum"';
        break;
      case ListColumnType.NumberColumn:
        this.value_calculation_type = RexCalculationType.JAVASCRIPT;
        this.value_calculation_javascript_definition = '0';
        break;
      case ListColumnType.BooleanColumn:
        this.value_calculation_type = RexCalculationType.JAVASCRIPT;
        this.value_calculation_javascript_definition = 'false';
        break;
      case ListColumnType.DateTimeColumn:
        this.value_calculation_type = RexCalculationType.JAVASCRIPT;
        this.value_calculation_javascript_definition = 'new Date()';
        break;
      case ListColumnType.IconColumn:
        this.value_calculation_type = RexCalculationType.JAVASCRIPT;
        this.value_calculation_javascript_definition = '"check"';
        break;
      case ListColumnType.BadgesColumn:
        this.value_calculation_type = RexCalculationType.JAVASCRIPT;
        this.value_calculation_javascript_definition = '[{ name: "Test", slug: "test", text_color: "#70777d", background_color: "#eceded" }]';
        break;
      case ListColumnType.ActionsColumn:
        this.value_calculation_type = RexCalculationType.CONSTANT;
        break;
    }
  }
}

export const ListColumnType = {
  IdColumn: 'ListColumn::IdColumn',
  WorkspaceIdColumn: 'ListColumn::WorkspaceIdColumn',
  IncinerationOrderIdColumn: 'ListColumn::IncinerationOrderIdColumn',
  StringColumn: 'ListColumn::StringColumn',
  UpdatedAtColumn: 'ListColumn::UpdatedAtColumn',
  CreatedAtColumn: 'ListColumn::CreatedAtColumn',
  NumberColumn: 'ListColumn::NumberColumn',
  BooleanColumn: 'ListColumn::BooleanColumn',
  DateTimeColumn: 'ListColumn::DateTimeColumn',
  IconColumn: 'ListColumn::IconColumn',
  BadgesColumn: 'ListColumn::BadgesColumn',
  ActionsColumn: 'ListColumn::ActionsColumn',
} as const;

export const CreatableListColumnTypes = [
  ListColumnType.StringColumn,
  ListColumnType.NumberColumn,
  ListColumnType.BooleanColumn,
  ListColumnType.DateTimeColumn,
  ListColumnType.IconColumn,
  ListColumnType.BadgesColumn
];

export type ListColumnType = typeof ListColumnType[keyof typeof ListColumnType];
