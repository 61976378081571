// eslint-disable-next-line max-classes-per-file
import {ProcessFlowBaseNode, ProcessFlowBaseNodeSettings} from './process-flow-base-node';
import {RexCalculationType} from '../../calculation-type';

export class ConditionalNode extends ProcessFlowBaseNode {
  settings: ConditionalNodeSettings;

  constructor(v: Partial<ConditionalNode>) {
    super(v);
    this.type = 'ProcessFlow::ConditionalNode';

    this.supportsOutlets = true;
    this.supportedOutlets = [];
    this.settings = new ConditionalNodeSettings(v.settings ?? {});
  }
}

export class ConditionalNodeSettings extends ProcessFlowBaseNodeSettings {
  outlets_calculation_type: RexCalculationType;
  outlets_calculation_javascript_definition: string;
  outlets: string[];

  constructor(v: Partial<ConditionalNodeSettings> = {}) {
    super(v);

    this.outlets_calculation_type = v.outlets_calculation_type ?? RexCalculationType.JAVASCRIPT;
    this.outlets_calculation_javascript_definition = v.outlets_calculation_javascript_definition ?? '';
    this.outlets = v.outlets ?? [];
  }
}
