import {Subscription} from 'rxjs';
import {BucketType, ProcessRun, User} from '@paperlessio/sdk/api/models';

export interface ChannelIdentifier {
  channel: string;
  subscription_uuid?: string;
  [key: string]: any;
}

export interface BucketChannelIdentifier extends ChannelIdentifier {
  bucket_type: BucketType;
  bucket_id: number;
}

export interface ProcessRunsChannelIdentifier extends ChannelIdentifier {
  process_definition_id: number;
}

export interface ListRowsChannelIdentifier extends ChannelIdentifier {
  list_id: number;
}

export type ChannelIdentifierSubscription = {
  channelSubscription: any;
  observableSubscription: Subscription;
}

export interface ChannelMessage {
  data: any;
  type: ChannelMessageType;
  acting_subscription_uuid?: string;
}

export const enum ChannelMessageType {
  BucketChannelEvents_BlocksChangedEvent = 'BucketChannelEvents.BlocksChangedEvent',
  BucketChannelEvents_EditorsChangedEvent = 'BucketChannelEvents.EditorsChangedEvent',
  BucketChannelEvents_ParticipantsChangedEvent = 'BucketChannelEvents.ParticipantsChangedEvent',
  BucketChannelEvents_SelectedBlocksChangedEvent = 'BucketChannelEvents.SelectedBlocksChangedEvent',
  BucketChannelEvents_TokensChangedEvent = 'BucketChannelEvents.TokensChangedEvent',
}

export interface EditorsChangedItem {
  subscription_uuid: string;
  user: User
}

export interface EditorsChangedMessage extends ChannelMessage{
  data: EditorsChangedItem[],
}

export interface SelectedBlocksChangedItem {
  subscription_uuid: string,
  selected_block_ids: number[],
}

export type ChannelReceiver = {
  [propName: string]: (data) => void
}

export interface ChannelSubscriber {
  identifier: ChannelIdentifier;
  receiver: ChannelReceiver;
}

export interface BucketChannelSubscriber extends ChannelSubscriber {
  identifier: BucketChannelIdentifier;
}

export interface ProcessRunsChannelSubscriber extends ChannelSubscriber {
  identifier: ProcessRunsChannelIdentifier;
  receiver: ProcessRunsChannelReceiver;
}

export interface ListRowsChannelSubscriber extends ChannelSubscriber {
  identifier: ListRowsChannelIdentifier;
  receiver: ListRowsChannelReceiver;
}

export type ProcessRunsChannelReceiverDataType = Pick<ProcessRun, 'id' | 'state'> & {label_slugs: string[]};

export type ProcessRunsChannelReceiver = {
  created: (data: ProcessRunsChannelReceiverDataType) => void;
  updated: (data: ProcessRunsChannelReceiverDataType) => void;
  incinerated: (data: ProcessRunsChannelReceiverDataType) => void;
  reincarnated: (data: ProcessRunsChannelReceiverDataType) => void;
}

export type ListRowsChannelReceiverDataType = any;

export type ListRowsChannelReceiver = {
  created: (data: ListRowsChannelReceiverDataType) => void;
  updated: (data: ListRowsChannelReceiverDataType) => void;
  upserted: (data: ListRowsChannelReceiverDataType) => void;
  destroyed: (data: ListRowsChannelReceiverDataType) => void;
}

export const enum ChannelActions {
  blocks_selected = 'blocks_selected',
}

export enum BucketChannelEvents {
  BlocksChangedEvent = 'BucketChannelEvents.BlocksChangedEvent',
  EditorsChangedEvent = 'BucketChannelEvents.EditorsChangedEvent',
  SelectedBlocksChangedEvent = 'BucketChannelEvents.SelectedBlocksChangedEvent',
  TokensChangedEvent = 'BucketChannelEvents.TokensChangedEvent',
  ParticipationFlowChangedEvent = 'BucketChannelEvents.ParticipationFlowChangedEvent',
}

export enum ProcessRunsChannelEvents {
  created = 'created',
  updated = 'updated',
  incinerated = 'incinerated',
  reincarnated = 'reincarnated'
}
