// eslint-disable-next-line max-classes-per-file
import {ProcessFlowBaseNode, ProcessFlowBaseNodeSettings} from './process-flow-base-node';
import {RexCalculationType} from '../../calculation-type';
import {ProcessFlowNodeOutlet} from './process-flow-node-outlets';

export class HttpNode extends ProcessFlowBaseNode {
  constructor(v: Partial<HttpNode>) {
    super(v);
    this.type = 'ProcessFlow::HttpNode';
    this.settings = new HttpNodeSettings(v.settings);
    if (v.recalculated_settings) {
      this.recalculated_settings = new HttpNodeSettings(v.recalculated_settings);
    }

    this.supportedOutlets = [
      ProcessFlowNodeOutlet.COMPLETED_OUTLET
    ];
  }

  settings: HttpNodeSettings;
  recalculated_settings: HttpNodeSettings;
}

export class HttpNodeSettings extends ProcessFlowBaseNodeSettings {
  constructor(v: Partial<HttpNodeSettings> = {}) {
    super(v);

    this.payload_mode = v.payload_mode ?? 'custom_body';
    this.completion_mode = v.completion_mode ?? 'direct';
    this.http_method = v.http_method ?? 'post';

    this.url_calculation_type = v.url_calculation_type ?? RexCalculationType.CONSTANT;
    this.url_calculation_javascript_definition = v.url_calculation_javascript_definition ?? '';
    this.url = v.url ?? '';

    this.headers = v.headers ?? [];

    this.body_calculation_type = v.body_calculation_type ?? RexCalculationType.CONSTANT;
    this.body_calculation_javascript_definition = v.body_calculation_javascript_definition ?? '';
    this.body = v.body ?? '';

    this.api_version_name = v.api_version_name ?? '';

    this.submission_id_calculation_type = v.submission_id_calculation_type ?? RexCalculationType.CONSTANT;
    this.submission_id_calculation_javascript_definition = v.submission_id_calculation_javascript_definition ?? '';
    this.submission_id = v.submission_id ?? null;
  }

  payload_mode: 'custom_body' | 'submission';
  completion_mode: 'direct' | 'after_successful_delivery';
  http_method: 'post';

  url_calculation_type: RexCalculationType;
  url_calculation_javascript_definition?: string;
  url?: string;

  headers: {name: string, value: string}[];

  body_calculation_type: RexCalculationType;
  body_calculation_javascript_definition?: string;
  body?: string;

  api_version_name?: string;

  submission_id_calculation_type: RexCalculationType;
  submission_id_calculation_javascript_definition?: string;
  submission_id?: string;
}
