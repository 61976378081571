import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {BaseServiceV1} from './base.service.v1';
import {Serializer} from '@paperlessio/sdk/api/serializers';
import {BaseList} from '@paperlessio/sdk/api/util';
import {WorkspaceMembership} from '@paperlessio/sdk/api/models';

@Injectable({providedIn: 'root'})
export class WorkspaceMembershipService extends BaseServiceV1<WorkspaceMembership> {
  constructor(http: HttpClient) {
    super(http);
    this.endpoint = `workspace_memberships`;
    this.serializer = new Serializer<WorkspaceMembership>(WorkspaceMembership);
  }

  fetchByWorkspace(workspace_id: number, params: {} = {}): Observable<BaseList<WorkspaceMembership>> {
    params = Object.assign({}, params, {ordered: ''});
    return super.fetchByWorkspace(workspace_id, params);
  }
}

