import {excluded} from './util/excluded';
import {OldListColumn, ListColumnSize} from './column';
import {ReminderSettings} from './reminder-settings';
import {Organization} from './organization';
import {Designs} from './designs';
import {WorkspaceAddon} from './workspace-addon';
import {Blob} from './blob';

export class Workspace {
  static columns: OldListColumn[] = [
    new OldListColumn({property: 'name', fixed: true, align: 'left', size: ListColumnSize.col04}),
    new OldListColumn({property: 'memberships', fixed: true, align: 'left', size: ListColumnSize.col02}),
    new OldListColumn({property: 'my_role', fixed: true, align: 'left', size: ListColumnSize.col02})
  ];

  static personal_columns: OldListColumn[] = [
    new OldListColumn({property: 'name', fixed: true, align: 'left', size: ListColumnSize.col04}),
    new OldListColumn({property: 'organization_name', fixed: true, align: 'left', size: ListColumnSize.col02}),
    new OldListColumn({property: 'my_role', fixed: true, align: 'left', size: ListColumnSize.col02})
  ];

  id: number;
  name: string;
  organization_id: number;
  workspace_id: number; // to duplicate an existing workspace
  creator_id: number;
  type: string;
  icon: Blob;
  settings: WorkspaceSettings;
  reminder_settings: ReminderSettings;
  @excluded organization: Organization;
  @excluded designs: Designs;
  @excluded workspace_addons: WorkspaceAddon[];

  constructor(v: Partial<Workspace> = {}) {
    Object.assign(this, v);

    this.settings = new WorkspaceSettings(v.settings ?? {});
    this.reminder_settings = new ReminderSettings(v.reminder_settings ?? {});
    this.organization = new Organization(v.organization ?? {});
    this.designs = new Designs(v.designs as any);
    this.workspace_addons = v.workspace_addons ? v.workspace_addons.map(wa => new WorkspaceAddon(wa)) : [];
    this.icon = new Blob(v.icon);

    if (!this.settings.letter && (this.name?.length ?? 0) > 0) {
      this.settings.letter = this?.name[0] ?? '';
    }
  }

  get organization_name() {
    return this.organization?.name;
  }
}

export class MicroFrontend<T> {
  remoteEntry: string;

  /**
   * To identify the microFrontend and if a workspace has this specific mfe.
   *
   * ABH for example: The delegate ticket feature checks the other workspace for presence of the same mfe.
   */
  mfeIdentifier?: string;
  exposedModule: string;
  exposedModuleName: string;
  exposedModalModule: string;
  exposedModalModuleName: string;
  exposedNavigationComponent: string;
  exposedNavigationComponentName: string;
  routePath: string;
  config: T;
  disabled?: boolean;

  constructor(v: Partial<MicroFrontend<T>> = {}) {
    this.remoteEntry = v.remoteEntry;
    this.mfeIdentifier = v.mfeIdentifier;
    this.exposedModule = v.exposedModule;
    this.exposedModuleName = v.exposedModuleName;
    this.exposedModalModule = v.exposedModalModule;
    this.exposedModalModuleName = v.exposedModalModuleName;
    this.exposedNavigationComponent = v.exposedNavigationComponent;
    this.exposedNavigationComponentName = v.exposedNavigationComponentName;
    this.routePath = v.routePath;
    this.config = v.config;
    this.disabled = v.disabled ?? false;
  }
}

export class WorkspaceSettings {
  type: WorkspaceType;
  description: string;
  color = '#0083F6';
  email_defaults: EmailDefaults;
  letter: string;
  microFrontends: MicroFrontend<unknown>[];

  constructor(v: Partial<WorkspaceSettings> = {}) {
    this.type = v.type ?? WorkspaceType.General;
    this.description = v.description;
    this.color = v.color;
    this.email_defaults = new EmailDefaults(v.email_defaults ?? {});
    this.letter = v.letter ?? '';

    this.microFrontends = (v.microFrontends ?? []).map(p => new MicroFrontend<unknown>(p));
  }
}

export class EmailDefaults {
  subject: string;
  content: string;

  constructor(v: Partial<EmailDefaults>) {
    this.subject = v.subject;
    this.content = v.content;
  }
}

export enum WorkspaceType {
  Sales = 'sales',
  Hr = 'hr',
  Forms = 'forms',
  General = 'general'
}
