import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Block, BlockMutationResult, BucketType, ListableBucketParams} from '@paperlessio/sdk/api/models';
import {BlockSerializer} from '@paperlessio/sdk/api/serializers';
import * as Sentry from '@sentry/angular';
import {BlockMutationCollection} from './block-mutation.type';
import {BaseServiceV1} from '@paperlessio/sdk/api/services';
import {BaseList} from '@paperlessio/sdk/api/util';

@Injectable({providedIn: 'root'})
export class BlockService extends BaseServiceV1<Block> {
  serializer: BlockSerializer;

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'blocks';
    this.serializer = new BlockSerializer();
  }

  allFromBucket(bucket_type: BucketType, bucket_id: number): Observable<Block[]> {
    const params: ListableBucketParams<Block> = {
      bucket_type,
      bucket_id,
      all: true
    };

    return this.fetch(params)
      .pipe(
        map(res => res.data.map(obj => this.serializer.fromJson(obj))),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        })
      );
  }

  mutate = (blockMutationCollection: BlockMutationCollection): Observable<BlockMutationResult> => {
    return this.http
      .post<BlockMutationResult>(this.BASE_URL_V1 + 'block_mutations', JSON.stringify(blockMutationCollection))
      .pipe(
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        })
      );
  };

  //#region unsupported stuff
  /**
   * @deprecated
   */
  get(id: number, params: {} = {}): Observable<Block> {
    throw new Error('Not Supported.');
  }

  /**
   * @deprecated
   */
  duplicate(id: number, name: string): Observable<Block> {
    throw new Error('Not Supported.');
  }

  /**
   * @deprecated
   */
  delete(id: number): Observable<Block> {
    throw new Error('Not Supported.');
  }

  /**
   * @deprecated
   */
  update(id: number, data: Partial<Block> | Block): Observable<Block> {
    throw new Error('Not Supported.');
  }

  /**
   * @deprecated
   */
  create(data: Partial<Block> | Block, params: {} = {}): Observable<Block> {
    throw new Error('Not Supported.');
  }

  /**
   * @deprecated
   */
  fetchByOrganization(organization_id: number, params: {} = {}): Observable<BaseList<Block>> {
    throw new Error('Not Supported.');
  }

  /**
   * @deprecated
   */
  fetchByWorkspace(workspace_id: number, params: {} = {}): Observable<BaseList<Block>> {
    throw new Error('Not Supported.');
  }
  //#endregion
}
