import {Injectable, OnDestroy} from '@angular/core';
import {Template} from '@paperlessio/sdk/api/models';
import {TemplateService} from '@paperlessio/sdk/api/services';
import {ToastService} from '@paperlessio/sdk/api/util';
import {CurrentWorkspaceStore} from './current-workspace.store';
import {WorkspaceBaseStore} from './workspace.base.store';

@Injectable({providedIn: 'root'})
export class TemplateStore extends WorkspaceBaseStore<Template, TemplateService> implements OnDestroy {
  constructor(service: TemplateService, toast: ToastService, userMembershipStore: CurrentWorkspaceStore) {
    super(service, toast, userMembershipStore);
  }

  ngOnDestroy() {
    this.cleanup();
  }
}
