import {Expandable} from '@paperlessio/sdk/api/util';
import {OldListColumn, ListColumnSize, OldListColumnType} from './column';
import {User} from './user';
import {ProcessFlow} from './process_flow/process-flow';
import {AppendProcessFlowNode} from './process_flow/nodes/append-process-flow-node';
import {ForEachNode} from './process_flow/nodes/for-each-node';

export class ProcessDefinitionVersion {
  static expandable = new Expandable<ProcessDefinitionVersion, 'creator' | 'process_flows'>();

  static columns: OldListColumn[] = [
    new OldListColumn({property: 'revision_number', fixed: true, align: 'left', size: ListColumnSize.col01}),
    new OldListColumn({property: 'state', default: true, align: 'left', type: OldListColumnType.badge, size: ListColumnSize.col03}),
    new OldListColumn({property: 'published_at', default: true, align: 'left', type: OldListColumnType.timeAgo, size: ListColumnSize.col02}),
    new OldListColumn({property: 'unpublished_at', default: true, align: 'left', type: OldListColumnType.timeAgo, size: ListColumnSize.col02}),
    new OldListColumn({property: 'creator', default: false, align: 'left', type: OldListColumnType.creator}),
  ];

  static defaultColumns = ProcessDefinitionVersion.columns.filter(x => x.default && !x.fixed).map(x => x.property);

  id: number;
  workspace_id: number;
  process_definition_id: number;
  process_definition_version_id: number; // for duplicate
  revision_number: number;
  revision_message: string;

  creator: User;
  process_flows: ProcessFlow[];

  created_at: Date;
  updated_at: Date;
  published_at: Date;
  unpublished_at: Date;
  archived_at: Date;

  constructor(v: Partial<ProcessDefinitionVersion> = {}) {
    this.id = v.id;
    this.workspace_id = v.workspace_id;
    this.process_definition_id = v.process_definition_id;
    this.revision_number = v.revision_number;
    this.revision_message = v.revision_message;

    this.creator = v.creator != null ? new User(v.creator) : null;
    this.process_flows = v.process_flows != null ? v.process_flows.map(x => new ProcessFlow(x)) : [];

    this.created_at = v.created_at != null ? new Date(v.created_at) : undefined;
    this.updated_at = v.updated_at != null ? new Date(v.updated_at) : undefined;
    this.published_at = v.published_at != null ? new Date(v.published_at) : undefined;
    this.unpublished_at = v.unpublished_at != null ? new Date(v.unpublished_at) : undefined;
    this.archived_at = v.archived_at != null ? new Date(v.archived_at) : undefined;
  }

  get state() {
    if (this.archived_at) {
      return 'archived';
    } else if (this.unpublished_at) {
      return 'unpublished';
    } else if (this.published_at) {
      return 'published';
    } else {
      return 'draft';
    }
  }

  // TODO: Optimize
  get allAppendFlowNodes(): AppendProcessFlowNode[] {
    return this.process_flows
      .map(f => f.nodes.filter(n => n.type === 'ProcessFlow::AppendProcessFlowNode'))
      .reduce((prev, curr) => {
        prev.push(...curr);
        return prev;
      }, []) as AppendProcessFlowNode[];
  }

  // TODO: Optimize
  get allForEachNodes(): ForEachNode[] {
    return this.process_flows
      .map(f => f.nodes.filter(n => n.type === 'ProcessFlow::ForEachNode'))
      .reduce((prev, curr) => {
        prev.push(...curr);
        return prev;
      }, []) as ForEachNode[];
  }

  updateProcessFlow(processFlow: ProcessFlow) {
    const index = this.process_flows.findIndex(pf => pf.id === processFlow.id);

    if (index >= 0) {
      this.process_flows[index] = processFlow;
    }
  }
}
