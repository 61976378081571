@if (!!triggerTemplateRef) {
  <div tabindex="-1" #trigger class="trigger" (click)="toggle()">
    <ng-container *ngTemplateOutlet="triggerTemplateRef"></ng-container>
  </div>
}

<div #panel
     class="panel {{panelClasses.join(' ')}} elevation-{{elevation}}"
     [class.css-based-visibility]="hideMethod === 'css'"
     [ngClass]="{'popper-visible': visible, 'nested-dropdown': nestedDropdown, 'borderless': borderless, 'transparent': transparent}"
     [style.z-index]="zIndex"
     [style.max-height.px]="maxPanelHeight"
     [style.border-radius.px]="panelBorderRadius"
>
  @if (showArrow) {
    <div class="dropdown-arrow" data-popper-arrow></div>
  }

  <div class="inner-panel"
       [style.border-radius.px]="panelBorderRadius"
       (click)="onInnerPanelClick()">
    <ng-content></ng-content>
  </div>
</div>
