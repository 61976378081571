<div *transloco="let t; read: 'session.header.other_actions'"
     class="dropdown-menu position-relative d-inline-block border-0 m-0">

  @if (!invalidParticipantState && (submittable?.forwarding_allowed || submittable?.delegation_allowed)) {
    <a [routerLink]="['', {outlets: {modal: participantIsViewer ? ['m', token, 'forward', 'invite'] : ['m', token, 'forward']}}]"
       class="dropdown-item">
      {{t('forward')}}
    </a>
  }

  @if (!invalidParticipantState && showDeclineButton) {
    <a [routerLink]="['', {outlets: {modal: ['m', token, 'decline']}}]"
       class="dropdown-item">
      {{t('decline')}}
    </a>
  }

  @if (!invalidParticipantState) {
    <a [routerLink]="['', {outlets: {modal: ['m', token, 'preview']}}]"
       class="dropdown-item">
      {{t('preview')}}
    </a>
  }

  <a [href]="t('paperless_infos_url')"
     class="dropdown-item"
     target="_blank">
    {{t('paperless_infos')}}
  </a>

  <div class="dropdown-divider"></div>

  <a class="dropdown-item"
     href="mailto:support@paperless.io">
    {{t('help')}}
  </a>

  <div class="dropdown-divider"></div>

  <a [href]="t('terms_and_conditions_url')"
     class="dropdown-item"
     target="_blank">
    {{t('terms_and_conditions')}}
  </a>

  <a [href]="t('privacy_url')"
     class="dropdown-item"
     target="_blank">
    {{t('privacy')}}
  </a>

  <a [href]="t('accessibility_url')"
     class="dropdown-item"
     target="_blank">
    {{t('accessibility')}}
  </a>

  <a [href]="t('legal_url')"
     class="dropdown-item"
     target="_blank">
    {{t('legal')}}
  </a>
</div>
