// eslint-disable-next-line max-classes-per-file
import {ProcessFlowBaseNode, ProcessFlowBaseNodeSettings} from './process-flow-base-node';
import {RexCalculationType} from '../../calculation-type';
import {ProcessFlowNodeOutlet} from './process-flow-node-outlets';

export class SetProcessRunStateNode extends ProcessFlowBaseNode {
  constructor(v: Partial<SetProcessRunStateNode>) {
    super(v);
    this.type = 'ProcessFlow::SetProcessRunStateNode';
    this.settings = new SetProcessRunStateNodeSettings(v.settings);
    if (v.recalculated_settings) {
      this.recalculated_settings = new SetProcessRunStateNodeSettings(v.recalculated_settings);
    }

    this.supportedOutlets = [
      ProcessFlowNodeOutlet.COMPLETED_OUTLET
    ];
  }

  settings: SetProcessRunStateNodeSettings;
  recalculated_settings: SetProcessRunStateNodeSettings;
}

export class SetProcessRunStateNodeSettings extends ProcessFlowBaseNodeSettings {
  state_calculation_type: RexCalculationType;
  state_calculation_javascript_definition: string;
  state: string;

  constructor(v: Partial<SetProcessRunStateNodeSettings> = {}) {
    super(v);

    this.state_calculation_type = v.state_calculation_type ?? RexCalculationType.CONSTANT;
    this.state_calculation_javascript_definition = v.state_calculation_javascript_definition ?? '';
    this.state = v.state ?? '';
  }
}
