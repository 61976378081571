import {excluded} from './util/excluded';
import {coerceNumberProperty} from '@angular/cdk/coercion';

export class Blob {
  id: number;
  signed_id: string;
  filename: string;
  content_type: string;
  byte_size: number;
  checksum: string;
  created_at: Date;
  url: string;
  preview_url: string;
  metadata: BlobMetadata;
  variants: BlobVariants;
  direct_upload: {
    url: string;
    headers: object;
  };
  file: File;
  uploadIdentifier: string;
  uploadError: Error;
  uploadPercentage: number;
  uploadTrustedLocalFileUrl;
  @excluded internalSignedId: string;

  constructor(v: Partial<Blob> = {}) {
    // for SubmitEventValues of normal FileUploadInputBlocks we have a signed_id.
    // for SecureFileUploadInputBlocks we don't have a normal Block at hand but an url which includes the signed_id as part of the url
    this.internalSignedId = v?.internalSignedId ?? v?.signed_id ?? v?.url?.split('/')?.pop();

    if (v?.url) {
      // we explicitly DO NOT set signed_id, as it is used to indicate a new blob to be used
      this.id = v.id;
      this.filename = v.filename;
      this.content_type = v.content_type;
      this.byte_size = v.byte_size;
      this.checksum = v.checksum;
      this.created_at = v.created_at ? new Date(v.created_at) : null;
      this.url = v.url;
      this.preview_url = v.preview_url;
      this.metadata = new BlobMetadata(v.metadata);
      this.variants = new BlobVariants(v.variants);
      this.direct_upload = v.direct_upload;
    } else {
      // is there is nothing present, we explicitly set signed_id to null as this will indicate to the API that no attachment is present
      this.signed_id = null;
    }
  }

  get default_url() {
    return this.variants?.default_url || this.url;
  }

  get attachmentUrl(): string {
    return this.url + '?disposition=attachment';
  }

  get idFromSignedId(): number {
    return blobIdFromSignedId(this.signed_id ?? this.internalSignedId);
  }

  // use json serialization to conform with API requirements
  toJSON(): string {
    if (this.signed_id === null) {
      return null; // reset blob (i.e. remove attachment from attachable)
    } else if (this.signed_id?.length) {
      return this.signed_id; // new signed id
    } else {
      return undefined; // will exclude object from json serialization
    }
  }

  toDirectUploadJSON(): object {
    return {
      filename: this.filename,
      content_type: this.content_type,
      byte_size: this.byte_size,
      checksum: this.checksum
    };
  }

  static forUpload(file: File, uploadIdentifier: string): Blob {
    const blob = new Blob();
    blob.file = file;
    blob.uploadIdentifier = uploadIdentifier;
    blob.uploadPercentage = 0;
    blob.signed_id = null;
    blob.filename = file.name;
    blob.content_type = file.type;
    blob.byte_size = file.size;
    return blob;
  }
}

export class BlobMetadata {
  analyzed: boolean;
  identified: boolean;
  width: number;
  height: number;
  mediabox: Mediabox;
  cropbox: Mediabox;

  constructor(v: Partial<BlobMetadata> = {}) {
    this.analyzed = v.analyzed;
    this.identified = v.identified;
    this.width = v.width;
    this.height = v.height;
    this.mediabox = v.mediabox ? new Mediabox(v.mediabox) : null;
    this.cropbox = v.cropbox ? new Mediabox(v.cropbox) : null;
  }
}

export class BlobVariants {
  default_url: string;
  default_webp_url: string;
  default_2x_url: string;
  default_2x_webp_url;

  constructor(v: Partial<BlobVariants> = {}) {
    this.default_url = v.default_url;
    this.default_webp_url = v.default_webp_url;
    this.default_2x_url = v.default_2x_url;
    this.default_2x_webp_url = v.default_2x_webp_url;
  }
}

export class Mediabox {
  x: string;
  y: string;
  width: string;
  height: string;

  constructor(v: Partial<Mediabox> = {}) {
    Object.assign(this, v);
  }
}

export function blobIdFromSignedId(signedId: string): number {
  // if it does not look like a signed id, return null
  if (!signedId?.length || !signedId.startsWith('ey')) {
    return null;
  }

  try {
    const [json, _rest] = signedId.split('--').map(v => atob(v));
    const data = JSON.parse(json);

    // now we have someting like
    // '{"_rails":{"data":7,"pur":"blob_id"}}'

    // we are only interested in the message
    return coerceNumberProperty(data?.['_rails']?.data);
  } catch (e) {
    console.log('Error parsing signed id: ', signedId);
    return null;
  }
}
