// eslint-disable-next-line max-classes-per-file
import {ProcessFlowBaseNode, ProcessFlowBaseNodeSettings} from './process-flow-base-node';
import {AppendProcessFlowNodeJoinType, AppendProcessFlowNodeSettings} from './append-process-flow-node';
import {RexCalculationType} from '../../calculation-type';
import {ProcessFlowNodeOutlet} from './process-flow-node-outlets';

export class ForEachNode extends ProcessFlowBaseNode {
  constructor(v: Partial<ForEachNode>) {
    super(v);
    this.type = 'ProcessFlow::ForEachNode';
    this.settings = new ForEachNodeSettings(v.settings);
    if (v.recalculated_settings) {
      this.recalculated_settings = new ForEachNodeSettings(v.recalculated_settings);
    }

    this.supportedOutlets = [
      ProcessFlowNodeOutlet.COMPLETED_OUTLET
    ];
  }

  settings: ForEachNodeSettings;
  recalculated_settings: ForEachNodeSettings;
}

export class ForEachNodeSettings extends ProcessFlowBaseNodeSettings {
  append_process_flow_settings: AppendProcessFlowNodeSettings;
  join_type: AppendProcessFlowNodeJoinType;
  iterables_calculation_type: RexCalculationType;
  iterables_calculation_javascript_definition: string;
  iterables: any[];

  constructor(v: Partial<ForEachNodeSettings> = {}) {
    super(v);

    this.append_process_flow_settings = new AppendProcessFlowNodeSettings(v.append_process_flow_settings as AppendProcessFlowNodeSettings);
    this.join_type = v.join_type ?? AppendProcessFlowNodeJoinType.AND;
    this.iterables_calculation_type = v.iterables_calculation_type ?? RexCalculationType.JAVASCRIPT;
    this.iterables_calculation_javascript_definition = v.iterables_calculation_javascript_definition ?? '';
    this.iterables = v.iterables ?? [];
  }
}
