import {inject, Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import {throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {FormMetaInformation} from './form-meta-information';
import {environment} from '@environment';
import {FormParticipant} from './form-participant';
import {Serializer} from '@paperlessio/sdk/api/serializers';
import {FormRequestParams} from './form-request-params';

@Injectable({providedIn: 'root'})
export class SessionFormService {
  BASE_URL = environment.api.core + 'v1/';
  endpoint = 'participation';

  private http = inject(HttpClient);

  getFormMetaInformation(form_public_token: string) {
    return this.http.get<FormMetaInformation>(this.BASE_URL + this.endpoint + '/form_meta_information/' + form_public_token,)
      .pipe(
        map(json => {
          return new Serializer<FormMetaInformation>(FormMetaInformation).fromJson(json);
        }),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        })
      );
  }

  createFormRequest(formRequestParams: FormRequestParams) {
    return this.http.post<{participants: FormParticipant[]}>(this.BASE_URL + this.endpoint + '/form_requests', formRequestParams)
      .pipe(
        map(json => {
          return json;
        }),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        })
      );
  }
}
