import {UserSettings} from './user-settings';
import {NotificationSettings} from './notification-settings';
import {IWorkspaceMembership} from './workspace-membership.interface';
import {IOrganizationMembership} from './organization-membership.interface';
import {Blob} from './blob';
import {excluded} from './util/excluded';
import {normalizeLocale} from '@paperlessio/sdk/api/util';

interface UserIdentifierHashes {
  chatwoot: string;
  directus: string;
}

export class User {
  id: number;
  email: string;
  email_verified: boolean;
  name: string;
  position: string;
  avatar: Blob;
  locale: string;
  settings = new UserSettings();
  notification_settings = new NotificationSettings();
  created_at: Date;
  updated_at: Date;
  marketing_tracking_data: any;
  stored_signature_image: Blob;
  @excluded scim_managed: boolean;
  @excluded password: string;
  @excluded password_confirmation: string;
  @excluded workspace_memberships: IWorkspaceMembership[] = [];
  @excluded organization_memberships: IOrganizationMembership[] = [];
  @excluded identifier_hashes: UserIdentifierHashes = {chatwoot: '', directus: ''};

  constructor(v: Partial<User> = {}) {
    Object.assign(this, v);
    this.avatar = new Blob(v.avatar);
    this.locale = normalizeLocale(v.locale);
    this.settings = new UserSettings(v.settings ?? {});
    this.notification_settings = new NotificationSettings(v.notification_settings);
    this.stored_signature_image = new Blob(v.stored_signature_image);
    this.scim_managed = v.scim_managed ?? false;
    this.identifier_hashes = v.identifier_hashes ?? {chatwoot: '', directus: ''};
  }

  get avatarUrl() {
    if (!this.avatar.default_url) {
      return 'https://eu.ui-avatars.com/api/?color=70777d&background=f8f8f8&font-size=0.4&size=128&name=' + this.initials;
    } else {
      return this.avatar.preview_url ? this.avatar.preview_url : this.avatar.default_url;
    }
  }

  get initials() {
    if (this.name) {
      const names = this.name.split(' ');
      let initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials.toUpperCase();
    } else {
      return this.email.substring(0, 2).toUpperCase();
    }
  }

  get firstWorkspaceId(): number {
    return this.workspace_memberships.sort((a, b) => a.id - b.id)?.[0]?.workspace_id || 0;
  }
}

export class SubscribedUser extends User {
  private readonly userColorCount = 9;
  subscription_uuid: string;
  colorIndex: number;
  selected_page_ids: number[] = [];

  constructor(v: Partial<User> = {}) {
    super(v);
  }

  setSubscriptionUuid(subscription_uuid) {
    this.subscription_uuid = subscription_uuid;
    return this;
  }

  setColorIndex(colorIndex: number) {
    this.colorIndex = colorIndex % this.userColorCount;
    return this;
  }
}
