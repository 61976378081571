export class ListScope {
  id: number;
  list_id: number;
  updated_at: Date;
  created_at: Date;
  type: ListScopeType;
  scoping_organization_id: number;
  scoping_workspace_id?: number;
  scoping_process_definition_id?: number;

  constructor(v?: Partial<ListScope>) {
    this.id = v?.id ?? 0;
    this.list_id = v?.list_id ?? 0;
    this.updated_at = v?.updated_at != null ? new Date(v.updated_at) : undefined;
    this.created_at = v?.created_at != null ? new Date(v.created_at) : undefined;
    this.type = v?.type ?? ListScopeType.ProcessRunListScope;
    this.scoping_organization_id = v?.scoping_organization_id ?? 0;
    this.scoping_workspace_id = v?.scoping_workspace_id ?? 0;
    this.scoping_process_definition_id = v?.scoping_process_definition_id ?? 0;
  }
}

export const ListScopeType = {ProcessRunListScope: 'ListScope::ProcessRunListScope',} as const;

export type ListScopeType = typeof ListScopeType[keyof typeof ListScopeType];
