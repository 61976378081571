// eslint-disable-next-line max-classes-per-file
import {ProcessFlowBaseNode, ProcessFlowBaseNodeSettings} from './process-flow-base-node';
import {RexCalculationType} from '../../calculation-type';
import {ProcessFlowNodeOutlet} from './process-flow-node-outlets';

export class SetProcessRunNameNode extends ProcessFlowBaseNode {
  constructor(v: Partial<SetProcessRunNameNode>) {
    super(v);
    this.type = 'ProcessFlow::SetProcessRunNameNode';
    this.settings = new SetProcessRunNameNodeSettings(v.settings);
    if (v.recalculated_settings) {
      this.recalculated_settings = new SetProcessRunNameNodeSettings(v.recalculated_settings);
    }

    this.supportedOutlets = [
      ProcessFlowNodeOutlet.COMPLETED_OUTLET
    ];
  }

  settings: SetProcessRunNameNodeSettings;
  recalculated_settings: SetProcessRunNameNodeSettings;
}

export class SetProcessRunNameNodeSettings extends ProcessFlowBaseNodeSettings {
  name_calculation_type: RexCalculationType;
  name_calculation_javascript_definition: string;
  name: string;

  constructor(v: Partial<SetProcessRunNameNodeSettings> = {}) {
    super(v);

    this.name_calculation_type = v.name_calculation_type ?? RexCalculationType.CONSTANT;
    this.name_calculation_javascript_definition = v.name_calculation_javascript_definition ?? '';
    this.name = v.name ?? '';
  }
}
