import {DOCUMENT} from '@angular/common';
import {inject, Injectable} from '@angular/core';
import {User} from '@paperlessio/sdk/api/models';
import {WINDOW} from '@paperlessio/sdk/util/helpers';
import {CHATWOOT_CONFIG, ChatwootConfig, ENVIRONMENT_NAME} from '@paperlessio/sdk/util/tokens';

/** Toggle state for chatwoot widget. */
export type ChatwootToggleState = 'close' | 'open' | null;

export type ChatwootUser = {
  name: string; // Name of the user
  avatar_url: string; // Avatar URL
  email: string; // Email of the user
  identifier_hash: string; // Identifier Hash generated based on the webwidget hmac_token
  phone_number: string; // Phone Number of the user
  description: string; // description about the user
  country_code: string; // Two letter country code
  city: string; // City of the user
  company_name: string; // company name
};

export type ChatwootSettings = {
  baseUrl: string; // Base URL of the chatwoot instance
  locale: string; // Locale of the user
  darkMode: 'light' | 'dark'; // Dark mode of the widget
};

export type ChatwootWidget = {
  isLoaded: boolean;
  user: ChatwootUser;
  setUser: (userId: string, user: ChatwootUser) => void;
  setCustomAttributes: (attributes: Record<string, any>) => void;
  setConversationCustomAttributes: (attributes: Record<string, any>) => void;
  toggleBubbleVisibility: (visibility: 'show' | 'hide') => void;
  toggle: (toggleState: ChatwootToggleState) => void;
  reset: () => void;
};

export type ChatwootSDK = {
  run: (options: {websiteToken: string; baseUrl: string}) => void;
};

/**
 * Service for interacting with Chatwoot widget.
 */
@Injectable({providedIn: 'root'})
export class ChatwootWidgetService {
  private document = inject(DOCUMENT);
  private chatwootConfig: ChatwootConfig = inject(CHATWOOT_CONFIG);
  private environmentName: string = inject(ENVIRONMENT_NAME);
  private window: Window & {
    $chatwoot: ChatwootWidget,
    chatwootSettings: ChatwootSettings,
    chatwootSDK: ChatwootSDK
  } = inject(WINDOW) as any;

  private readonly websiteToken = this.chatwootConfig.website_token;
  private readonly baseUrl = this.chatwootConfig.base_url;
  private readonly scriptUrl = this.baseUrl + '/packs/js/sdk.js';

  private user: User;
  private chatwootWidgetReady = false;

  constructor() {
    if (!this.websiteToken || !this.baseUrl) {
      console.warn('[ChatwootWidgetService] Config values not provided for Chatwoot Widget');
      return;
    }
  }

  public start(user: User) {
    this.user = user;

    if (this.canUseChatwoot) {
      this.loadChatwootSDK();
    }
  }

  public stop() {
    this.user = null;
    this.resetChatwoot();
  }

  /**
   * Initialize chatwoot, injecting script into the DOM, configuring
   * and setting up listeners.
   * @returns { void }
   */
  private loadChatwootSDK(): void {
    // grab existing script elements
    const firstScriptElement: HTMLScriptElement =
      this.document.getElementsByTagName('script')[0];

    // create new script element
    const newScriptElement: HTMLScriptElement =
      this.document.createElement('script');
    newScriptElement.src = this.scriptUrl;
    newScriptElement.defer = true;
    newScriptElement.async = true;

    // add new script element to DOM
    firstScriptElement.parentNode.insertBefore(
      newScriptElement,
      firstScriptElement
    );

    // attach on load listener for script to init chatwoot.
    newScriptElement.onload = (): void => {
      this.onChatwootLoaded();
    };
  }

  /**
   * Fires on Chatwoot load.
   */
  private onChatwootLoaded(): void {
    // init with chatwoot settings.
    this.setChatwootSettings();

    // run chatwoot widget
    this.window.chatwootSDK.run({
      websiteToken: this.websiteToken,
      baseUrl: this.baseUrl,
    });

    this.window.addEventListener('chatwoot:error', function (e) {
      console.error(e);
    });

    this.window.addEventListener('chatwoot:ready', async ready => {
      /**
       * The SDK does not give us an "open" event, so we have to
       * listen to clicks on the chat bubble.
       */
      this.getChatwootBubbleElement()?.addEventListener(
        'click',
        this.onBubbleClick.bind(this)
      );

      this.chatwootWidgetReady = true;

      if (this.user) {
        await this.setUser();
      } else {
        this.resetChatwoot();
      }
    });
  }

  /**
   * Sets user for chatwoot widget.
   */
  private async setUser(): Promise<void> {
    if (this.user && this.canUseChatwoot && this.chatwootWidgetReady) {
      this.window.$chatwoot.setUser(this.user.id.toString(), this.buildChatwootUser(this.user));
      this.window.$chatwoot.setCustomAttributes({organization_id: this.user?.organization_memberships?.[0]?.organization?.id});
    }
  }

  /**
   * Handle clicks on Chatwoot bubble.
   * @param { Event } event - click event.
   * @returns { void }
   */
  private async onBubbleClick(event: Event): Promise<void> {
    const currentChatwootUser = this.window.$chatwoot?.user;

    if (!currentChatwootUser) {
      console.warn('[ChatwootWidgetService] Chatwoot user not set, resetting chatwoot.');
      // clear any lingering state as it's gotten out of sync.
      this.resetChatwoot();

      // set the user again
      await this.setUser();
    }
  }

  /**
   * Reset chatwoot. Should be called on logout.
   * @returns { void }
   */
  private resetChatwoot(): void {
    this.window.$chatwoot?.reset();
  }

  /**
   * Sets chatwoot settings.
   * @returns { void }
   */
  private setChatwootSettings(): void {
    this.window.chatwootSettings = {
      baseUrl: this.baseUrl,
      locale: this.user?.locale?.slice(0, 2), // DE or EN
      darkMode: 'light',
    };
  }

  /**
   * Get chatwoot bubble element.
   * @returns { Element|null } chatwoot bubble element.
   */
  private getChatwootBubbleElement(): Element | null {
    return this.document.getElementsByClassName('woot-widget-bubble')?.[0];
  }

  /**
   * Popout a chatwoot session in a new browser window.
   * @returns { void }
   */
  public popoutChatWindow(): void {
    if (this.canUseChatwoot) {
      (this.document.defaultView as any).$chatwoot?.popoutChatWindow();
    }
  }

  /**
   * Toggle chat window.
   * @param toggleState - toggle state, if null, will toggle the opposite of the current state.
   * @returns { void }
   */
  public toggleChatWindow(toggleState: ChatwootToggleState = null): void {
    if (this.canUseChatwoot) {
      this.window.$chatwoot?.toggle(toggleState);
    }
  }

  /**
   * Hide chatwoot bubble.
   * @returns { void }
   */
  public hideBubble(): void {
    try {
      (this.document.defaultView as any).$chatwoot?.toggleBubbleVisibility(
        'hide'
      );
    } catch (e) {
      console.error('[ChatwootWidgetService]', e);
    }
  }

  /**
   * Show chatwoot bubble.
   * @returns { void }
   */
  public showBubble(): void {
    if (this.canUseChatwoot) {
      try {
        this.window.$chatwoot?.toggleBubbleVisibility(
          'show'
        );
      } catch (e) {
        console.error('[ChatwootWidgetService]', e);
      }
    }
  }

  /**
   * Whether chatwoot can be used by the user.
   * As the support chat can be disabled by the organization, a single organization membership with an organization that has support chat
   * disabled will disable chatwoot entirely.
   * @returns { boolean } - true if chatwoot can be used, false otherwise.
   */
  public get canUseChatwoot(): boolean {
    return this.environmentName !== 'development' && !(window as any).Cypress && this.user && !this.user.organization_memberships.some(m => m.organization.support_chat_enabled === false);
  }

  /**
   * As chatwoot requires a specific user object, we need to build it from the user object we have.
   */
  private buildChatwootUser(user: User): ChatwootUser {
    return {
      identifier_hash: user.identifier_hashes.chatwoot,
      name: user.name,
      // this forces chatwoot to always set the user cookie again
      avatar_url: user.avatarUrl+'#'+new Date().getTime(),
      email: user.email,
      phone_number: '',
      description: '',
      country_code: user.locale.substring(0, 2),
      city: '',
      company_name: user.organization_memberships?.map(m => m.organization.name).join(', ')
    };
  }
}
