import {Expandable} from '@paperlessio/sdk/api/util';
import {ListColumnSize, OldListColumn, OldListColumnType} from './column';
import {User} from './user';
import {ProcessDefinitionVersion} from './process-definition-version';
import {List} from './list/list';

export class ProcessDefinition {
  static expandable = new Expandable<ProcessDefinition, 'creator' | 'published_process_definition_version' | 'default_list' | 'default_list.list_columns'>();

  static columns: OldListColumn[] = [
    new OldListColumn({property: 'name', default: true, fixed: true, align: 'left', size: ListColumnSize.col03, type: OldListColumnType.bucketName}),
    new OldListColumn({property: 'state', default: true, align: 'left', type: OldListColumnType.badge, size: ListColumnSize.col02}),
    new OldListColumn({property: 'updated_at', default: true, align: 'left', type: OldListColumnType.timeAgo}),
    new OldListColumn({property: 'creator', default: true, align: 'left', type: OldListColumnType.creator}),
  ];

  static defaultColumns = ProcessDefinition.columns.filter(x => x.default && !x.fixed).map(x => x.property);

  id: number;
  workspace_id: number;
  published_process_definition_version_id: number;
  default_list_id: number;
  name: string;
  description: string;
  slug: string;

  creator: User;
  published_process_definition_version: ProcessDefinitionVersion;
  default_list: List;

  created_at: Date;
  updated_at: Date;

  constructor(v: Partial<ProcessDefinition>) {
    this.id = v.id;
    this.workspace_id = v.workspace_id;
    this.published_process_definition_version_id = v.published_process_definition_version_id;
    this.default_list_id = v.default_list_id;
    this.name = v.name;
    this.description = v.description;
    this.slug = v.slug;

    this.creator = v.creator != null ? new User(v.creator) : null;
    this.published_process_definition_version =
      v.published_process_definition_version != null ? new ProcessDefinitionVersion(v.published_process_definition_version) : null;
    this.default_list = v?.default_list != null ? new List(v.default_list) : null;

    this.updated_at = v.updated_at != null ? new Date(v.updated_at) : null;
    this.created_at = v.created_at != null ? new Date(v.created_at) : null;
  }

  // Get a fake state to be able to use the existing badge column type and the already supported badge types
  get state(): string {
    if (this.published_process_definition_version) {
      return 'published';
    } else {
      return 'draft';
    }
  }

  get published(): boolean {
    return this.published_process_definition_version != null;
  }
}
