import {ListScope} from './list-scope';
import {ListColumn} from './list-column';
import {ListSettings} from './list-settings';
import {Expandable} from '@paperlessio/sdk/api/util';

export class List {
  static expandable = new Expandable<List, 'list_columns' | 'list_scopes'>();

  id: number;
  workspace_id: number;
  name: string;
  updated_at: Date;
  created_at: Date;
  type: ListType;
  list_columns?: ListColumn[];
  list_scopes?: ListScope[];
  icon: string;
  show_in_workspace_navigation: boolean;
  localized_attributes: any;
  settings: ListSettings;

  constructor(v?: Partial<List>) {
    this.id = v?.id ?? 0;
    this.workspace_id = v?.workspace_id ?? 0;
    this.name = v?.name ?? '';
    this.updated_at = v?.updated_at != null ? new Date(v.updated_at) : undefined;
    this.created_at = v?.created_at != null ? new Date(v.created_at) : undefined;
    this.type = v?.type ?? ListType.ProcessRunList;
    this.list_columns = v?.list_columns != null ? v.list_columns.map(lc => new ListColumn(lc)) : [];
    this.list_scopes = v?.list_scopes != null ? v.list_scopes.map(ls => new ListScope(ls)) : [];
    this.icon = v?.icon ?? '';
    this.show_in_workspace_navigation = !!v?.show_in_workspace_navigation;
    this.localized_attributes = v?.localized_attributes;
    this.settings = new ListSettings(v?.settings);
  }
}

export const ListType = {ProcessRunList: 'List::ProcessRunList',} as const;

export type ListType = typeof ListType[keyof typeof ListType];
