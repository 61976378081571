import {BaseSettings, BaseStyles, Expandable, LOCALE} from '@paperlessio/sdk/api/util';
import {Bucket} from './bucket';
import {OldListColumn, ListColumnSize, OldListColumnType} from './column';
import {Designs} from './designs';
import {ParticipationFlow} from './participation-flow';
import {Participant} from './participant';
import {User} from './user';
import {excluded} from './util/excluded';

export class Template extends Bucket {
  static expandable = new Expandable<Template, 'designs' | 'creator' | 'participants' | 'participation_flow'>();

  // todo: define columns
  static columns: OldListColumn[] = [
    new OldListColumn({property: 'name', fixed: true, align: 'left', type: OldListColumnType.bucketName}),
    new OldListColumn({property: 'updated_at', default: true, align: 'left', type: OldListColumnType.timeAgo, size: ListColumnSize.col02}),
    new OldListColumn({property: 'usage_count', default: false, align: 'left'}),
    new OldListColumn({property: 'creator', default: true, align: 'left', type: OldListColumnType.creator, size: ListColumnSize.col02}),
  ];

  static defaultColumns = Template.columns.filter(x => x.default).map(x => x.property);

  id: number;
  name: string;
  description: string;
  settings: TemplateSettings;
  styles: TemplateStyles;
  @excluded designs: Designs;
  document_id?: number;
  form_version_id?: number;
  organization_id: number;
  creator_id: number;
  workspace_id: number;
  template_id?: number; // to duplicate an existing template
  blueprint_id?: number; // to duplicate an existing blueprint (or to be more specific: the underlying template)
  page_count: number;
  participation_flow: ParticipationFlow;
  participants: Participant[];
  participant_completed_redirect_url?: string;
  updated_at: Date;
  @excluded usage_count: number;
  @excluded creator: User;
  forwarding_allowed: boolean;
  delegation_allowed: boolean;

  constructor(v: Partial<Template> = {}) {
    super();
    Object.assign(this, v);

    this.id = v.id ?? null;
    this.workspace_id = v.workspace_id ?? null;
    this.creator_id = v.creator_id ?? null;
    this.name = v.name ?? '';
    this.description = v.description ?? '';
    this.participation_flow = v.participation_flow ? new ParticipationFlow(v.participation_flow) : null;
    this.participants = v.participants ?? [];
    this.participant_completed_redirect_url = v.participant_completed_redirect_url ?? undefined;

    this.settings = new TemplateSettings(v.settings ?? {});
    this.styles = new TemplateStyles(v.styles ?? {});
    this.designs = new Designs(v.designs as any);
    this.updated_at = v.updated_at ? new Date(v.updated_at) : new Date();
    this.document_id = v.document_id;
    this.creator = v.creator ? new User(v.creator) : undefined;
    this.forwarding_allowed = v.forwarding_allowed ?? true;
    this.delegation_allowed = v.delegation_allowed ?? true;
  }
}

export class TemplateStyles extends BaseStyles {
  constructor(v: Partial<TemplateStyles> = {}) {
    super(v);
  }
}

export class TemplateSettings extends BaseSettings {
  wizardMode: boolean;
  default_locale: LOCALE;
  locales: LOCALE[];
  qes: boolean;
  hideCreatorInAuditTrail: boolean;

  constructor(v: Partial<TemplateSettings> = {}) {
    super(v);

    this.locales = v?.locales ?? [];
    this.wizardMode = v?.wizardMode ?? false;
    this.qes = v.qes ?? false;
    this.hideCreatorInAuditTrail = v.hideCreatorInAuditTrail ?? false;
  }
}
