import {Observable, throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {catchError, filter, map} from 'rxjs/operators';
import {BaseList, BaseSerializer} from '@paperlessio/sdk/api/util';
import * as Sentry from '@sentry/angular';
import {API_BASE_PATH} from '@paperlessio/sdk/util/tokens';
import {inject} from '@angular/core';
import {ListableParams} from '@paperlessio/sdk/api/models';

export class BaseServiceV1<T> {
  serializer: BaseSerializer<T>;
  endpoint: string;

  BASE_URL = inject(API_BASE_PATH) + 'v1/';
  BASE_URL_V1 = inject(API_BASE_PATH) + 'v1/';

  constructor(public http: HttpClient) {
  }

  fetch(params: Record<string, any> & ListableParams<T> = {}): Observable<BaseList<T>> {
    return this.http
      .get<BaseList<T>>(`${this.BASE_URL + this.endpoint}`, {params})
      .pipe(
        map(res => {
          res.data = res.data.map(obj => this.serializer.fromJson(obj));
          return res;
        }),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        })
      );
  }

  fetchByWorkspace(workspace_id: number, params: Record<string, any> & ListableParams<T> = {}): Observable<BaseList<T>> {
    return this.http
      .get<BaseList<T>>(`${this.BASE_URL + this.endpoint}`, {
        params: {
          workspace_id: workspace_id.toString(),
          ...params
        }
      })
      .pipe(
        map(res => {
          res.data = res.data.map(obj => this.serializer.fromJson(obj));
          return res;
        }),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        })
      );
  }

  fetchByOrganization(organization_id: number, params: Record<string, any> & ListableParams<T> = {}): Observable<BaseList<T>> {
    return this.http
      .get<BaseList<T>>(`${this.BASE_URL + 'organizations/' + organization_id + '/' + this.endpoint}`, {params})
      .pipe(
        map(res => {
          res.data = res.data.map(obj => this.serializer.fromJson(obj));
          return res;
        }),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        })
      );
  }

  get(id: number, params = {}): Observable<T> {
    return this.http
      .get<T>(`${this.BASE_URL + this.endpoint}/${id}`, {params})
      .pipe(
        filter(x => !!x),
        map(res => this.serializer.fromJson(res)),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        }));
  }

  create(data: T | Partial<T>, params = {}): Observable<T> {
    return this.http
      .post<T>(`${this.BASE_URL + this.endpoint}`, this.serializer.toJson(data), {params})
      .pipe(
        map(res => this.serializer.fromJson(res)),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        }));
  }

  duplicate(id: number, name: string): Observable<T> {
    return this.http
      .post<T>(`${this.BASE_URL + this.endpoint}/${id}/duplicate`, {name})
      .pipe(
        map(x => {
          return this.serializer.fromJson(x);
        }),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        })
      );
  }

  update(id: number, data: T | Partial<T>, params = {}): Observable<T> {
    return this.http
      .patch<T>(`${this.BASE_URL + this.endpoint}/${id}`, this.serializer.toJson(data), {params})
      .pipe(
        map(res => this.serializer.fromJson(res)),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        }));
  }

  delete(id: number): Observable<T> {
    return this.http
      .delete<T>(`${this.BASE_URL + this.endpoint}/${id}`).pipe(
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        })
      );
  }
}
