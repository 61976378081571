import {Injectable} from '@angular/core';
import {Partial} from '@paperlessio/sdk/api/models';
import { HttpClient } from '@angular/common/http';
import {Serializer} from '@paperlessio/sdk/api/serializers';
import {BaseServiceV1} from '@paperlessio/sdk/api/services';

@Injectable({
  providedIn: 'root'
})
export class PartialService extends BaseServiceV1<Partial> {
  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'partials';
    this.serializer = new Serializer<Partial>(Partial);
  }
}
