export const ProcessFlowNodeOutlet = {
  ANY_END_STATE_OUTLET: 'any_end_state',
  SUBMISSION_DISPATCHED_OUTLET: 'submission_dispatched',
  SUBMISSION_COMPLETED_OUTLET: 'submission_completed',
  SUBMISSION_DECLINED_OUTLET: 'submission_declined',
  SUBMISSION_CANCELLED_OUTLET: 'submission_cancelled',
  SUBMISSION_EXPIRED_OUTLET: 'submission_expired',
  COMPLETED_OUTLET: 'completed'
} as const;

export type ProcessFlowNodeOutlet = typeof ProcessFlowNodeOutlet[keyof typeof ProcessFlowNodeOutlet] | string;
