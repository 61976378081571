import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from '../../../environments/environment';
import * as Sentry from '@sentry/angular';

Sentry.init({
  dsn: environment.sentry_dsn.submission,
  release: 's-' + environment.release,
  environment: environment.name,
  enabled: environment.name !== 'development',
  sendDefaultPii: true,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  normalizeDepth: 10,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Controls for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'app.paperless.io',
    'app.staging.paperless.io',
    'api.paperless.io',
    'api.staging.paperless.io',
    'sfus.paperless.io',
    'sfus.staging.paperless.io',
    /^\/(?!\/)/
  ],
  // Session Replay
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  beforeSend(event, hint) {
    if (typeof hint?.originalException === 'string' || hint?.originalException instanceof String) {
      if (hint.originalException.includes('Http failure response for')) {
        return null;
      }
    }
    return event;
  }
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
