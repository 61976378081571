// eslint-disable-next-line max-classes-per-file
import {ProcessFlowBaseNode} from './process-flow-base-node';
import {ProcessFlowNodeOutlet} from './process-flow-node-outlets';

export class AndJoinNode extends ProcessFlowBaseNode {
  constructor(v: Partial<AndJoinNode>) {
    super(v);
    this.type = 'ProcessFlow::AndJoinNode';

    this.supportedOutlets = [
      ProcessFlowNodeOutlet.COMPLETED_OUTLET
    ];
  }
}
