import {GridState} from 'ag-grid-community';

export class ListView {
  id: number;
  name: string;
  slug: string;

  gridState: GridState;
  q: string;

  constructor(v?: Partial<ListView>) {
    this.id = v?.id;
    this.name = v?.name;
    this.slug = v?.slug;

    this.gridState = v?.gridState;
    this.q = v?.q;
  }
}
