import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BaseServiceV1} from './base.service.v1';
import {Serializer} from '@paperlessio/sdk/api/serializers';
import {Form} from '@paperlessio/sdk/api/models';

@Injectable({providedIn: 'root'})
export class FormsService extends BaseServiceV1<Form> {
  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'forms';
    this.serializer = new Serializer<Form>(Form);
  }
}
