import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HelloService {

  constructor(private http: HttpClient) {
  }

  /**
   * Say hello to the Paperless API. Retrieve a initial session ID and a CSRF-Token for further requests.
   *
   * This endpoint is the first endpoint a (browser based) client should call to start a session with the backend
   * by retrieving a cookie containing the session id and a cookie containing a CSRF-Token. Those values are needed
   * to be able to call the `createSession` endpoint and authenticate as user.
   */
  hello(): Observable<void> {
    return this.http.get<void>('/api/v1/hello');
  }

}
