import {StartNode} from '../process_flow/nodes/start-node';
import {AndJoinNode} from '../process_flow/nodes/and-join-node';
import {AppendProcessFlowNode} from '../process_flow/nodes/append-process-flow-node';
import {CreateDocumentNode} from '../process_flow/nodes/create-document-node';
import {ForEachNode} from '../process_flow/nodes/for-each-node';
import {ManualTriggerNode} from '../process_flow/nodes/manual-trigger-node';
import {OrJoinNode} from '../process_flow/nodes/or-join-node';
import {SetProcessRunCustomDataNode} from '../process_flow/nodes/set-process-run-custom-data-node';
import {SetProcessRunStateNode} from '../process_flow/nodes/set-process-run-state-node';
import {SetProcessRunNameNode} from '../process_flow/nodes/set-process-run-name-node';
import {SendEmailNode} from '../process_flow/nodes/send-email-node';
import {HttpNode} from '../process_flow/nodes/http-node';
import {SetProcessRunLabelsNode} from '../process_flow/nodes/set-process-run-labels-node';
import {RequestFormNode} from '../process_flow/nodes/request-form-node';
import {ProcessFlowNodeType} from '../process_flow/nodes/process-flow-node-type';
import {ConditionalNode} from '../process_flow/nodes/conditional-node';

export function parseProcessFlowNode(json: any) {
  switch (json.type as ProcessFlowNodeType) {
    case 'ProcessFlow::StartNode':
      return new StartNode(json);
    case 'ProcessFlow::AndJoinNode':
      return new AndJoinNode(json);
    case 'ProcessFlow::AppendProcessFlowNode':
      return new AppendProcessFlowNode(json);
    case 'ProcessFlow::CreateDocumentNode':
      return new CreateDocumentNode(json);
    case 'ProcessFlow::RequestFormNode':
      return new RequestFormNode(json);
    case 'ProcessFlow::ForEachNode':
      return new ForEachNode(json);
    case 'ProcessFlow::ManualTriggerNode':
      return new ManualTriggerNode(json);
    case 'ProcessFlow::OrJoinNode':
      return new OrJoinNode(json);
    case 'ProcessFlow::SetProcessRunCustomDataNode':
      return new SetProcessRunCustomDataNode(json);
    case 'ProcessFlow::SetProcessRunStateNode':
      return new SetProcessRunStateNode(json);
    case 'ProcessFlow::SetProcessRunNameNode':
      return new SetProcessRunNameNode(json);
    case 'ProcessFlow::SetProcessRunLabelsNode':
      return new SetProcessRunLabelsNode(json);
    case 'ProcessFlow::SendEmailNode':
      return new SendEmailNode(json);
    case 'ProcessFlow::HttpNode':
      return new HttpNode(json);
    case 'ProcessFlow::ConditionalNode':
      return new ConditionalNode(json);
    default:
      throw new Error(`[parseProcessFlowNode] Unknown node type: ${json.type}`);
  }
}
