import {InjectionToken} from '@angular/core';

/**
 * Basically mirrors the chatwoot config in the environment.
 */
export interface ChatwootConfig {
  base_url: string;
  website_token: string;
}

export const CHATWOOT_CONFIG = new InjectionToken<ChatwootConfig>('CHATWOOT_CONFIG');

export function provideChatwootConfig(value: ChatwootConfig) {
  return {
    provide: CHATWOOT_CONFIG,
    useValue: value
  };
}
