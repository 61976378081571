import {BaseSettings, Expandable} from '@paperlessio/sdk/api/util';
import {Bucket} from './bucket';
import {OldListColumn, ListColumnSize, OldListColumnType} from './column';
import {User} from './user';
import {Designs} from './designs';
import {Participant} from './participant';
import {ParticipationFlow} from './participation-flow';

export class Partial extends Bucket {
  static expandable = new Expandable<Partial, 'creator' | 'participants' | 'participation_flow'>();

  // todo: define columns
  static columns: OldListColumn[] = [
    new OldListColumn({property: 'name', fixed: true, align: 'left', size: ListColumnSize.col07}),
    new OldListColumn({property: 'updated_at', default: true, align: 'left', type: OldListColumnType.timeAgo, size: ListColumnSize.col02}),
    new OldListColumn({property: 'creator', default: true, align: 'left', type: OldListColumnType.creator, size: ListColumnSize.col02}),
  ];

  static defaultColumns = Partial.columns.filter(x => x.default).map(x => x.property);

  id: number;
  name: string;
  description: string;
  settings = new PartialSettings();
  last_used_at: number;
  usage_count: number;
  workspace_id: number;
  creator_id: number;
  creator: User;
  created_at: Date;
  updated_at: Date;
  root_block_id: number;
  designs: Designs;
  partial_id: number;
  participants: Participant[];

  constructor(v: any = {}) {
    super();
    Object.assign(this, v);
    this.settings = new PartialSettings(v?.settings ?? {});
    this.designs = new Designs(v?.designs);
    this.creator = v.creator ? new User(v.creator) : undefined;
    this.participation_flow = v?.participation_flow ? new ParticipationFlow(v.participation_flow): null;
    this.participants = v?.participants ?? [];
  }
}

export class PartialSettings extends BaseSettings {
  constructor(values = {}) {
    super(values);
    Object.assign(this, values);
  }
}
