import {Injectable} from '@angular/core';
import {ToastService} from '@paperlessio/sdk/api/util';
import {WorkspaceBaseStore} from './workspace.base.store';
import {CurrentWorkspaceStore} from './current-workspace.store';
import {FormsService} from '@paperlessio/sdk/api/services';
import {Form} from '@paperlessio/sdk/api/models';

@Injectable({providedIn: 'root'})
export class FormStore extends WorkspaceBaseStore<Form, FormsService> {
  constructor(
    protected service: FormsService,
    protected toast: ToastService,
    protected userMembershipStore: CurrentWorkspaceStore,
  ) {
    super(service, toast, userMembershipStore);
  }
}
