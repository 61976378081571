import {excluded} from './util/excluded';
import {OldListColumn, ListColumnSize} from './column';
import {Organization} from './organization';
import {User} from './user';
import {IOrganizationMembership} from './organization-membership.interface';

export class OrganizationMembership implements IOrganizationMembership {
  static columns: OldListColumn[] = [
    new OldListColumn({property: 'name', align: 'left', size: ListColumnSize.col05}),
    new OldListColumn({property: 'email', align: 'left', size: ListColumnSize.col04}),
    new OldListColumn({property: 'role', align: 'left', size: ListColumnSize.col02}),
    new OldListColumn({property: null, align: 'left', size: ListColumnSize.col01}),
  ];

  id: number;
  organization_id: number;
  @excluded organization: Organization;
  user_id: number;
  invite_email: string;
  invite_token: string;
  @excluded user: User;
  @excluded creator: User;
  role: OrganizationMembershipRole;
  created_at: Date;
  updated_at: Date;

  @excluded initials: string;
  @excluded avatar_url: string;

  @excluded support_membership: boolean; // used to mark memberships from paperless support team
  @excluded scim_managed: boolean; // used to mark memberships that are managed via SCIM

  constructor(v: Partial<OrganizationMembership> = {}) {
    Object.assign(this, v);
    this.organization = v.organization ? new Organization(v.organization) : null;
    this.user = v.user ? new User(v.user) : null;
    this.created_at = new Date(v.created_at);
    this.updated_at = new Date(v.updated_at);
    this.support_membership = v.support_membership ?? false;
    this.scim_managed = v.scim_managed ?? false;

    this.initials = this.invite_email?.substring(0, 1).toUpperCase();

    if (!this.avatar_url && !!this.initials) {
      this.avatar_url = 'https://eu.ui-avatars.com/api/?color=70777d&background=f8f8f8&font-size=0.4&name=' + this.initials;
    }
  }
}

export enum OrganizationMembershipRole {
  user = 'user',
  admin = 'admin',
  owner = 'owner'
}

